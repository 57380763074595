import React from "react";
import PropTypes from "prop-types";

import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Article from "../Article";
import AirTable from "./AirTable";
// import Meta from "./Meta";
// import Author from "./Author";
// import NextPrev from "./NextPrev";

const Ranking = props => {
  const {
    ranking,
    ranking: {
      type: category,
      slugs,
      data: { jon_nfl_rankings: jon_nfl_rankings, juntin_nfl_rankings: juntin_nfl_rankings },
      last_publication_date,
      first_publication_date
    },
    theme
  } = props;

  const author = slugs[0];

  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline theme={theme}>Come back soon to see our season long rankings.</Headline>
          {/* <Meta
          publishDate={last_publication_date}
          author={author}
          category={category}
          theme={theme}
        /> */}
        </header>
        {/* <AirTable theme={theme} ranking={jon_nfl_rankings} />
        <AirTable theme={theme} ranking={juntin_nfl_rankings} /> */}
        <footer>
          {/* <Share post={post} theme={theme} /> */}
          {/* <Author note={authornote} theme={theme} /> */}
          {/* <NextPrev next={nextPost} prev={prevPost} theme={theme} /> */}
          {/* <Comments slug={slugs[0]} facebook={facebook} theme={theme} /> */}
        </footer>
      </Article>
    </React.Fragment>
  );
};

Ranking.propTypes = {
  ranking: PropTypes.object.isRequired,
  // authornote: PropTypes.string.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default Ranking;
